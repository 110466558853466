import * as React from "react"
import { Frame, addPropertyControls, ControlType } from "framer"

interface JavaScriptComponentProps {
    width: number
    height: number
    code: string
}

const JavaScriptComponent: React.FC<JavaScriptComponentProps> = ({
    width,
    height,
    code,
}) => {
    const containerRef = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
        const script = document.createElement("script")
        script.textContent = code
        containerRef.current?.appendChild(script)

        return () => {
            containerRef.current?.removeChild(script)
        }
    }, [code])

    return (
        <Frame width={width} height={height} background="transparent">
            <div ref={containerRef} />
        </Frame>
    )
}

JavaScriptComponent.defaultProps = {
    width: 200,
    height: 200,
    code: 'console.log("Hello, World!");',
}

addPropertyControls(JavaScriptComponent, {
    code: {
        type: ControlType.String,
        title: "JavaScript Code",
        defaultValue: 'console.log("Hello, World!");',
        displayTextArea: true,
    },
})

export default JavaScriptComponent
